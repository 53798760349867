import React, { useContext, useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faVirus } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../Context/dataContext';
import '../styles.css'; // Import the updated styles.css

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Malaria = () => {
  const { data, loading } = useContext(DataContext);
  const [chartsData, setChartsData] = useState([]);
  const [tableDataByYear, setTableDataByYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2024');
  const chartRefs = useRef([]);

  const dataElements = ['TK4CEuVnrnA', 'uznN61KY1CW', 'mIFF41gVf3Q', 'PqFjy4eEjVu', 'kP0HOqZajJn'];

  useEffect(() => {
    if (!loading && data) {
      const { data1 } = data;
      const newChartsData = [];

      dataElements.forEach((elementId, index) => {
        const filteredRows = data1.rows.filter(row => row[0] === elementId);
        const labels = [...new Set(filteredRows.map(row => row[1]))]; // Periods
        const data = labels.map(label => {
          const row = filteredRows.find(r => r[0] === elementId && r[1] === label);
          return row ? parseFloat(row[3]) : 0;
        });

        newChartsData.push({
          labels,
          datasets: [{
            label: data1.metaData.items[elementId].name,
            data,
            backgroundColor: data.length > 1 ? labels.map(() => '#027ad5') : '#027ad5',
          }],
        });

        chartRefs.current[index] = React.createRef();
      });

      setChartsData(newChartsData);

      // Prepare table data for provinces as rows and data elements as columns for selected year
      const prepareTableDataByYear = () => {
        const provinces = data1.metaData.dimensions.ou.filter(ou => ou !== ''); // Exclude national level
        const tableRows = provinces.map(province => {
          const row = { province: data1.metaData.items[province]?.name || 'Unknown' };
          dataElements.forEach(element => {
            const dataRow = data1.rows.find(r => r[0] === element && r[1] === selectedYear && r[2] === province);
            row[element] = dataRow ? { value: parseFloat(dataRow[3]), color: getCellColor(element, parseFloat(dataRow[3])) } : { value: '-', color: 'transparent' };
          });
          return row;
        });
        return tableRows;
      };

      setTableDataByYear(prepareTableDataByYear());
    }
  }, [data, loading, selectedYear]);

  const getCellColor = (elementId, value) => {
    return 'transparent';
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Malaria Data',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  const handleDownload = (chartRef) => {
    const link = document.createElement('a');
    link.href = chartRef.current.toBase64Image();
    link.download = 'chart.png';
    link.click();
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
  };

  const getDataElementValue = (source, elementId) => {
    if (!data || !data[source]) return 'N/A';
    const row = data[source].rows.find(row => row[0] === elementId && row[1] === '2023');
    return row ? parseFloat(row[3]).toLocaleString() : 'N/A';
  };

  const dataBoxes = [
    {
      title: 'Total PF + Mix',
      elements: [
        { id: 'TK4CEuVnrnA', source: 'data1', label: 'Cases' },
      ],
      background: 'linear-gradient(to bottom right, #ffbc96, #fc7498)',
      icon: <FontAwesomeIcon icon={faVirus} className="icon-background" />,
    },
    {
      title: 'Positive Pv (double count)',
      elements: [
        { id: 'uznN61KY1CW', source: 'data1', label: 'Deaths' }
      ],
      background: 'linear-gradient(to bottom right, #8cc8f8, #3798e6)',
      icon: <FontAwesomeIcon icon={faVirus} className="icon-background" />,
    },
    {
      title: 'RDT tested cases',
      elements: [
        { id: 'mIFF41gVf3Q', source: 'data1', label: 'Tests' }
      ],
      background: 'linear-gradient(to bottom right, #82d9d1, #34d6bd)',
      icon: <FontAwesomeIcon icon={faVirus} className="icon-background" />,
    },
    {
      title: 'Microscopy tested cases',
      elements: [
        { id: 'PqFjy4eEjVu', source: 'data1', label: 'Positive' }
      ],
      background: 'linear-gradient(to bottom right,  #f19ff6, #c17efe)',
      icon: <FontAwesomeIcon icon={faVirus} className="icon-background" />,
    },
    {
      title: 'ABER',
      elements: [
        { id: 'kP0HOqZajJn', source: 'data1', label: 'Treatment' }
      ],
      background: 'linear-gradient(to bottom right,#ffd700, #ffa500)',
      icon: <FontAwesomeIcon icon={faVirus} className="icon-background" />,
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <>
          <div className="data-boxes-container1">
            {dataBoxes.map((box, index) => (
              <div key={index} className="data-box" style={{ background: box.background, color: 'white', position: 'relative' }}>
                <div className="data-element">
                  <h3>{box.title}</h3>
                  {box.elements.map((element, idx) => (
                    <p key={idx}>
                      {element.label} | {data && data[element.source] ? getDataElementValue(data[element.source], element.id) : 'N/A'}
                    </p>
                  ))}
                </div>
                <div className="icon-container">{box.icon}</div>
              </div>
            ))}
          </div>
          <div className="charts-container">
            {chartsData.map((chartData, index) => (
              <div className="chart-item" key={index}>
                <Bar data={chartData} options={options} ref={chartRefs.current[index]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[index])} />
              </div>
            ))}
          </div>
          <div className="table-container">
            <div className="year-selector">
              <label>Year  </label>
              {data?.data1?.metaData?.dimensions?.pe?.map(year => (
                <button
                  key={year}
                  className={`year-button ${year === selectedYear ? 'active' : ''}`}
                  onClick={() => handleYearButtonClick(year)}
                >
                  {year}
                </button>
              ))}
            </div>
            <h3></h3>
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Province</th>
                  {dataElements.map(elementId => (
                    <th key={elementId}>{data?.data1?.metaData?.items[elementId]?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableDataByYear.map((row, index) => (
                  <tr key={index}>
                    <td>{row.province}</td>
                    {dataElements.map(elementId => (
                      <td key={elementId} style={{ textAlign: 'right', backgroundColor: row[elementId].color }}>
                        {row[elementId].value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Malaria;
