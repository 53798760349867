import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaVirus, FaRibbon, FaBaby, FaSyringe, FaViruses, FaBed, FaHospitalUser } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { DataContext } from '../Context/dataContext';
import '../styles.css';
import { faMosquito } from '@fortawesome/free-solid-svg-icons';
import PopulationPyramid from './PopulationPyramid';
import ProvincialPopulationTable from './ProvincialPopulationTable';
import NationalAssemblyIndicator from './NationalAssemblyIndicator';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FaPersonPregnant, FaPerson, FaUserNurse, FaHeartPulse, FaHospital } from 'react-icons/fa6';

ChartJS.register(ArcElement, BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ChartDataLabels);

const Dashboard = () => {
  const { data, loading } = useContext(DataContext);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('All');
  const [provinces, setProvinces] = useState([]);
  const [pieData1, setPieData1] = useState(null);
  const [pieData2, setPieData2] = useState(null);
  const [barData1, setBarData1] = useState(null);
  const [barData2, setBarData2] = useState(null);
  const [heatmapData, setHeatmapData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (!loading && data && data.data1 && data.data2) {
      const { data1, data2, data3 } = data;

      const uniqueYears = [...new Set(data1.rows.map(row => row[1]))].sort();
      setYears(uniqueYears);

      const uniqueProvinces = [...new Set(data1.rows.map(row => row[2]))].sort();
      setProvinces(['All', ...uniqueProvinces]);

      const processTableData = (data) => {
        const dxElements = [
          'sXZaHrafJMr','U7hsRVYXgp6','A0Y0q8g6DHw', 'IumX9C8BpsA', 'MsjCV8o7A7x', 'Kn0SvNB4t3c', 'gwsX4vW4rsC', 'IYbhQ4RrKyb',
          'XIQnLHxsYha', 'GKmhH5tD0I2', 'XuY3jnMzXBv', 'i4exzX4dc2J', 'Bf0EsQFi4Ss', 'ATdDks1Z5W9',
          'gJoORvp1ce6', 'zwT2dgOZHS4', 'aibcRUBhGhk', 'sHvbCkapN1Q', 'xmO1wVVRPC6', 'pEhwmYSvvI6','MB9Y4omDJ9C'
        ];

        const tableRows = data.rows.reduce((acc, row) => {
          const [dx, period, orgUnit, value] = row;
          if (!dxElements.includes(dx)) return acc;

          const dxName = data.metaData.items[dx]?.name || dx;
          let existingRow = acc.find(item => item.dx === dxName);

          if (!existingRow) {
            existingRow = { dx: dxName, id: acc.length + 1, orgUnit };
            uniqueYears.forEach(year => {
              existingRow[year] = '';
            });
            acc.push(existingRow);
          }
          existingRow[period] = value;

          return acc;
        }, []);

        const totalsRow = { dx: 'Total', id: tableRows.length + 1 };
        uniqueYears.forEach(year => {
          totalsRow[year] = tableRows.reduce((total, row) => total + (parseFloat(row[year]) || 0), 0).toLocaleString();
        });
        tableRows.push(totalsRow);

        return tableRows;
      };

      const processedData = processTableData(data1);
      setTableData(processedData);
      setFilteredTableData(processedData);

      const processPieData = (data3, elementId) => {
        const dimension = 'LBwVBieQt45';
        const dimensionValues = data3.metaData.dimensions[dimension] || [];
        const labels = dimensionValues.map(value => data3.metaData.items[value]?.name || value);
        const dataValues = dimensionValues.map(value => {
          const row = data3.rows.find(row => row[0] === elementId && row[1] === '2024' && row[2] === value);
          return row ? parseFloat(row[4]) : 0;
        });

        return {
          labels,
          datasets: [
            {
              label: 'Value',
              data: dataValues,
              backgroundColor: ['#c1e5db', '#45c2e0', '#0570a6', '#ff7170', '#ffc851'],
              hoverBackgroundColor: ['#ff6384', '#36a2eb', '#cc65fe', '#ffce56', '#4bc0c0'],
            },
          ],
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'right',
              },
            },
            layout: {
              padding: {
                bottom: 20,
              },
            },
          },
        };
      };

      setPieData1(processPieData(data3, 'OAQvZ3RCJ7Q'));
      setPieData2(processPieData(data3, 'Kg6krzTWp9S'));

      const processBarData = (data1, elementId1, elementId2) => {
        const labels = data1.metaData.dimensions.pe.map(period => data1.metaData.items[period]?.name || period);
        const data1Values = data1.metaData.dimensions.pe.map(period => {
          const row = data1.rows.find(row => row[0] === elementId1 && row[1] === period);
          return row ? parseFloat(row[3]) : 0;
        });
        const data2Values = data1.metaData.dimensions.pe.map(period => {
          const row = data1.rows.find(row => row[0] === elementId2 && row[1] === period);
          return row ? parseFloat(row[3]) : 0;
        });

        return {
          labels,
          datasets: [
            {
              type: 'line',
              label: data1.metaData.items[elementId2]?.name || elementId2,
              data: data2Values,
              borderColor: '#45c2e0',
              backgroundColor: '#45c2e0',
              yAxisID: 'y-axis-2',
              datalabels: {
                color: '#000000',
              },
            },
            {
              type: 'bar',
              label: data1.metaData.items[elementId1]?.name || elementId1,
              data: data1Values,
              backgroundColor: '#0570a6',
              yAxisID: 'y-axis-1',
              datalabels: {
                color: '#ffffff',
              },
            }
          ],
          options: {
            plugins: {
              datalabels: {
                color: '#ffffff',
              },
            },
          },
        };
      };

      setBarData1(processBarData(data1, 'OAQvZ3RCJ7Q', 'IRKWA4P2O3Z'));
      setBarData2(processBarData(data1, 'Kg6krzTWp9S', 'XDNj0IV6PQ3'));

      const processHeatmapData = (data1, data2, dataElements1, dataElements2) => {
        const allPeriods = [...new Set([...data1.rows.map(row => row[1]), ...data2.rows.map(row => row[1])])].sort();
        const datasets = [];

        dataElements1.forEach((element, index) => {
          const dataValues = allPeriods.map(period => {
            const row = data1.rows.find(row => row[0] === element && row[1] === period);
            return row ? parseFloat(row[3]) : 0;
          });

          datasets.push({
            label: data1.metaData.items[element]?.name || element,
            data: dataValues,
            backgroundColor: `rgba(${index * 42}, ${255 - index * 42}, ${index * 42}, 0.6)`,
          });
        });

        dataElements2.forEach((element, index) => {
          const dataValues = allPeriods.map(period => {
            const row = data2.rows.find(row => row[0] === element && row[1] === period);
            return row ? parseFloat(row[3]) : 0;
          });

          datasets.push({
            label: data2.metaData.items[element]?.name || element,
            data: dataValues,
            backgroundColor: `rgba(${index * 42 + 126}, ${255 - (index * 42 + 126)}, ${index * 42 + 126}, 0.6)`,
          });
        });

        return {
          labels: allPeriods,
          datasets
        };
      };

      setHeatmapData(processHeatmapData(data1, data2, ['MB9Y4omDJ9C','U7hsRVYXgp6','A0Y0q8g6DHw', 'qcoksD9wNKl', 'IumX9C8BpsA'], ['gdkuK2eNEeK', 'khs4yQnJVjb', 'xIfpyFAGKOi', 'bbtXtRsKn1u']));
    }
  }, [data, loading]);

  const getDataElementValue = (sourceData, elementId) => {
    if (!sourceData || !sourceData.rows) return 'N/A';
    const row = sourceData.rows.find(row => row[0] === elementId && row[1] === '2023');
    return row ? parseFloat(row[3]).toLocaleString() : 'N/A';
  };

  const dataBoxes = [
    {
      title: 'Population',
      elements: [
        { id: '', source: '', label: '7,545,667' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaPerson className="icon-background" />,
      link: '/population',
    },
    {
      title: 'MMR [3.1.1]',
      elements: [
        { id: 'OjUHkTCv6cd', source: 'data1', label: 'Rate' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaBed className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'NMR [3.2.2]',
      elements: [
        { id: 'Yp9NxO9NCly', source: 'data1', label: 'Rate' }
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaBed className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'IMR',
      elements: [
        { id: 'ZGuni8UTDTK', source: 'data1', label: 'Rate' }
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaBed className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'U5MR [3.2.1]',
      elements: [
        { id: 'U2Tmx7kjSzc', source: 'data1', label: 'Rate' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaBed icon={faMosquito} className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'Stunting [2.2.1]',
      elements: [
        { id: 'bbtXtRsKn1u', source: 'data1', label: 'Percentage' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaHospitalUser className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'Wasting [2.2.2]',
      elements: [
        { id: 'roM1TpdI6bK', source: 'data1', label: 'Percentage' }
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaHospitalUser className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'Overweight',
      elements: [
        { id: 'sXZaHrafJMr', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right,  #00b3f4, #00d5fd)',
      icon: <FaBaby className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'ANC1-ANC4 Dropout',
      elements: [
        { id: 'MB9Y4omDJ9C', source: 'data1', label: 'Percentage' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaPersonPregnant className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'SBA [3.1.2]',
      elements: [
        { id: 'DYJX09D1lPL', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaUserNurse className="icon-background" />,
      link: '/mch',
    },
    {
      title: 'Penta 3 [3.b.1]',
      elements: [
        { id: 'ZDq9VuFHCYj', source: 'data1', label: 'Coverage' },
      ],
      background: 'linear-gradient(to bottom right,#00b3f4, #00d5fd)',
      icon: <FaSyringe icon={faMosquito} className="icon-background" />,
      link: '/vaccine',
    },
    {
      title: 'MCV 2 [3.b.1]',
      elements: [
        { id: 'U7hsRVYXgp6', source: 'data1', label: 'Coverage' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaSyringe className="icon-background" />,
      link: '/vaccine',
    },
    {
      title: 'HIV',
      elements: [
        { id: 'GwfwBw4iwnY', source: 'data1', label: 'Tested' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FaRibbon className="icon-background" />,
      link: '/hiv',
    },
    {
      title: 'TB',
      elements: [
        { id: 'khs4yQnJVjb', source: 'data2', label: 'Cases EP' },
      ],
      background: 'linear-gradient(to bottom right,  #00b3f4, #00d5fd)',
      icon: <FaHeartPulse className="icon-background" />,
      link: '/tb',
    },
    {
      title: 'Malaria',
      elements: [
        { id: 'MIOL3mDFJZf', source: 'data1', label: 'Cases' },
      ],
      background: 'linear-gradient(to bottom right, #00b3f4, #00d5fd)',
      icon: <FontAwesomeIcon icon={faMosquito} className="icon-background" />,
      link: '/malaria',
    },
    {
      title: 'Dengue',
      elements: [
        { id: 'A0Y0q8g6DHw', source: 'data1', label: 'Cases' }
      ],
      background: 'linear-gradient(to bottom right,#00b3f4, #00d5fd)',
      icon: <FontAwesomeIcon icon={faMosquito} className="icon-background" />,
      link: '/dengue',
    },

  ];

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const filterTableDataByProvince = (province) => {
    if (province === 'All') {
      setFilteredTableData(tableData);
    } else {
      const filteredData = tableData.filter(row => row.orgUnit === province || row.dx === 'Total');
      setFilteredTableData(filteredData);
    }
  };

  const handleProvinceChange = (event) => {
    const province = event.target.value;
    setSelectedProvince(province);
    filterTableDataByProvince(province);
  };

  return (
    <div className="dashboard-container">
      <div className="data-boxes-container">
        {dataBoxes.map((box, index) => (
          <div key={index} className="data-box" style={{ background: box.background, color: 'white', position: 'relative' }}>
            <div className="data-element">
              <h3>{box.title}</h3>
              {box.elements.map((element, idx) => (
                <p key={idx}>
                  {element.label} | {data && data[element.source] ? getDataElementValue(data[element.source], element.id) : ''}
                </p>
              ))}
            </div>
            <Link to={box.link} className="box-link">
              <FaArrowRight />
            </Link>
            <div className="icon-container">{box.icon}</div>
          </div>
        ))}
      </div>
      <div className="horizontal-container">
        <div className="population-pyramid-section" style={{ flex: 2.5 }}>
          <NationalAssemblyIndicator />
        </div>
        <div className="province-table-section" style={{ flex: 2.5 }}>
          <ProvincialPopulationTable />
        </div>
        <div className="population-pyramid-section" style={{ flex: 2 }}>
          <PopulationPyramid />
        </div>
      </div>
      {barData1 && barData2 && (
        <div className="horizontal-container">
          <div className="chart-item">
            <h5>OPD Visit and Visit Rate</h5>
            <Bar
              data={barData1}
              options={{
                responsive: true,
                scales: {
                  'y-axis-1': {
                    type: 'linear',
                    position: 'left',
                    title: {
                      display: true,
                      text: 'Number',
                    },
                  },
                  'y-axis-2': {
                    type: 'linear',
                    position: 'right',
                    title: {
                      display: true,
                      text: 'Rate',
                    },
                    grid: {
                      drawOnChartArea: false,
                    },
                  },
                },
                plugins: {
                  datalabels: {
                    color: '#ffffff',
                  },
                },
              }}
            />
          </div>
          <div className="chart-item">
            <h5>IPD Visit and Visit Rate</h5>
            <Bar
              data={barData2}
              options={{
                responsive: true,
                scales: {
                  'y-axis-1': {
                    type: 'linear',
                    position: 'left',
                    title: {
                      display: true,
                      text: 'Number',
                    },
                  },
                  'y-axis-2': {
                    type: 'linear',
                    position: 'right',
                    title: {
                      display: true,
                      text: 'Rate',
                    },
                    grid: {
                      drawOnChartArea: false,
                    },
                  },
                },
                plugins: {
                  datalabels: {
                    color: '#ffffff',
                  },
                },
              }}
            />
          </div>
        </div>
      )}
      {pieData1 && pieData2 && (
        <div className="horizontal-container">
          <div className="chart-item" style={{ position: 'relative', height: '400px' }}>
            <h5>OPD Visit - 2023</h5>
            <Pie data={pieData1} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'right' } }, layout: { padding: { bottom: 50 } } }} />
          </div>
          <div className="chart-item" style={{ position: 'relative', height: '400px' }}>
            <h5>IPD Visit - 2023</h5>
            <Pie data={pieData2} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'right' } }, layout: { padding: { bottom: 50 } } }} />
          </div>
        </div>
      )}
      {filteredTableData.length > 0 ? (
        <div className="table-container">
          <h5>Notifiable Diseases</h5>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Data</th>
                <th>2016</th>
                <th>2019</th>
                <th>2020</th>
                <th>2021</th>
                <th>2022</th>
                <th>2023</th>
                <th>2024</th>
              </tr>
            </thead>
            <tbody>
              {filteredTableData.map((row) => (
                <tr key={row.id}>
                  <td>{row.dx}</td>
                  <td style={{ textAlign: "right" }}>{row['2016']}</td>
                  <td style={{ textAlign: "right" }}>{row['2019']}</td>
                  <td style={{ textAlign: "right" }}>{row['2020']}</td>
                  <td style={{ textAlign: "right" }}>{row['2021']}</td>
                  <td style={{ textAlign: "right" }}>{row['2022']}</td>
                  <td style={{ textAlign: "right" }}>{row['2023']}</td>
                  <td style={{ textAlign: "right" }}>{row['2024']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      )}
      <div className="horizontal-container">
      </div>
    </div>
  );
};

export default Dashboard;
