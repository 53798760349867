import React from 'react';

const NationalAssemblyIndicator = () => {
  const indicators = [
    { title: 'Underweight among children under 5 (%)', value2020: 20, value2023_dhis2: 16, value2023_lsis: 24.3, value2023_un: '-', value2025: 15, value2030: 10 },
    { title: 'Stunting among children under 5 (height for age) (%)', value2020: 32, value2023_dhis2: 28, value2023_lsis: 32.8, value2023_un: '-', value2025: 27, value2030: 23 },
    { title: 'Infant mortality rate (per 1,000 live births)', value2020: 30, value2023_dhis2: 22, value2023_lsis: 25, value2023_un: 32.7, value2025: 20, value2030: '<12' },
    { title: 'Under-5 mortality rate (per 1,000 live births)', value2020: 40, value2023_dhis2: 32, value2023_lsis: 28, value2023_un: 40.38, value2025: 30, value2030: '<25' },
    { title: 'Maternal mortality ratio (per 100,000 live births)', value2020: 160, value2023_dhis2: 120, value2023_lsis:'-', value2023_un: 126, value2025: 110, value2030: '<70' },
    { title: 'Births attended by skilled birth attendants (%)', value2020: 80, value2023_dhis2: 83, value2023_lsis: 79.8, value2023_un: '-', value2025: 85, value2030: '>90' },
    { title: 'Pentavalent 3 vaccine coverage (%)', value2020: 95, value2023_dhis2: 95, value2023_lsis: 61.3, value2023_un: '-', value2025: 95, value2030: 100 },
    { title: 'Households with access to rural water supply (%)', value2020: 90, value2023_dhis2: 93, value2023_lsis: '-', value2023_un: '-', value2025: 95, value2030: '>95' },
    { title: 'Households using latrines (%)', value2020: 80, value2023_dhis2: 83, value2023_lsis: '-', value2023_un: '-', value2025: 85, value2030: '>90' },
    { title: 'National health insurance coverage (%)', value2020: 94, value2023_dhis2: 94.5, value2023_lsis: '-', value2023_un: '-', value2025: 96, value2030: '>96' },
    { title: 'Model health village certification (%)', value2020: 80, value2023_dhis2: 83, value2023_lsis: '-', value2023_un: '-', value2025: 85, value2030: '>85' },
  ];

  return (
    <div className="national-assembly-indicator">
      <h3>National Assembly Indicators</h3>
      <table className="custom-table">
        <thead>
          <tr>
            <th rowSpan="2">Indicator</th>
            <th rowSpan="2">2020</th>
            <th colSpan="3" style={{ textAlign: 'center' }}>2023</th>
            <th rowSpan="2">2025</th>
            <th rowSpan="2">2030</th>
          </tr>
          <tr>
            <th >DHIS2</th>
            <th >LSIS</th>
            <th >UN</th>
          </tr>
        </thead>
        <tbody>
          {indicators.map((indicator, index) => (
            <tr key={index}>
              <td>{indicator.title}</td>
              <td style={{ textAlign: 'right' }}>{indicator.value2020}</td>
              <td style={{ textAlign: 'right', backgroundColor: '#d1d0d0' }}>{indicator.value2023_dhis2}</td>
              <td style={{ textAlign: 'right', backgroundColor: '#d1d0d0' }}>{indicator.value2023_lsis}</td>
              <td style={{ textAlign: 'right', backgroundColor: '#d1d0d0' }}>{indicator.value2023_un}</td>
              <td style={{ textAlign: 'right' }}>{indicator.value2025}</td>
              <td style={{ textAlign: 'right' }}>{indicator.value2030}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NationalAssemblyIndicator;
