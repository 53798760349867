import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const PopulationPyramid = () => {
  const years = ['2000', '2023', '2050'];
  const ageGroups = ['0-4', '5-9', '10-14', '15-19', '20-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70-74', '75-79', '80-84', '85-89', '90-94', '95-99', '100+'];
  const data = {
    '2000': {
      'Male': [416788, 404606, 355368, 293574, 244510, 207649, 175886, 147518, 120198, 96599, 72606, 57671, 46222, 35298, 25005, 15255, 7444, 2713, 754, 233, 0],
      'Female': [402196, 389620, 345917, 288892, 240994, 204913, 173147, 144513, 118109, 96974, 78323, 63913, 51496, 40313, 30011, 19483, 10292, 4141, 1251, 447, 1]
    },
    '2023': {
      'Male': [380472, 394589, 388089, 362701, 350374, 339120, 313296, 276078, 233275, 189492, 156740, 127624, 100506, 71924, 45953, 26422, 16951, 4697, 1226, 189, 22],
      'Female': [364377, 381538, 380085, 353749, 343450, 336590, 312548, 275168, 231353, 189097, 159868, 135319, 108485, 79394, 51280, 32057, 22189, 6864, 2081, 380, 55]
    },
    '2050': {
      'Male': [350245, 356695, 363639, 368181, 373422, 376267, 375336, 355625, 345103, 327865, 317425, 285581, 245834, 185697, 125647, 77117, 39375, 14990, 3348, 356, 16],
      'Female': [335361, 342400, 349742, 354555, 360083, 363251, 363155, 346171, 338981, 323720, 317303, 294036, 258221, 202296, 146588, 99408, 58415, 26087, 6995, 948, 61]
    }
  };

  const createChartData = (year) => {
    return {
      labels: ageGroups,
      datasets: [
        {
          label: 'Male',
          data: data[year].Male.map((value) => -value),
          backgroundColor: 'rgba(54, 162, 235, 1)',
          borderColor: 'rgba(54, 162, 235, 1)',
          barPercentage: 0.9,
        },
        {
          label: 'Female',
          data: data[year].Female,
          backgroundColor: 'rgba(255, 99, 132, 1)',
          
          barPercentage: 0.9,
        },
      ],
    };
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        max: 400000,
        min: -400000,
        ticks: {
          callback: (value) => `${Math.abs(value).toLocaleString()}`,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        reverse: true, // Reverse the y-axis
        stacked: true,
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <div className="population-pyramid-container" style={{ height: '600px', overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      {years.map((year) => (
        <div key={year} style={{ width: '80%' }}>
          <Bar
            data={createChartData(year)}
            options={{ ...options, plugins: { ...options.plugins, title: { display: true, text: `Population Pyramid ${year}` } } }}
            height={350} // Adjust height here
          />
        </div>
      ))}
    </div>
  );
};

export default PopulationPyramid;
