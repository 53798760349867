import React, { useContext, useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faViruses, faSyringe } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../Context/dataContext';
import '../styles.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Vaccine = () => {
  const { data, loading } = useContext(DataContext);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [tableDataByYear, setTableDataByYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2024');
  const chartRefs = useRef([]);

  const dataElements1 = [
    ['jNh7rultv7M'],
    ['ZDq9VuFHCYj', 'nBuU9oPndeV'],
    ['pYuFEtRSvk7', 'rvy2uhxYI3s'],
    ['MoKPswMMmUB'],
    ['U7hsRVYXgp6', 'TXRyukUpK0Y']
  ];

  const dataElements2 = [
    ['TXJvVxvzAUi', 'EPLlXFNnZbw'],
    ['jhj62wuu0nf', 'h9NRRJ7HwVd']
  ];

  useEffect(() => {
    if (!loading && data) {
      const { data1, data2 } = data;

      const createChartData = (dataSource, dataElements) => {
        return dataElements.map((elements, chartIndex) => {
          const labels = [...new Set(dataSource.rows.map(row => row[1]))];
          const datasets = elements.map((dx, index) => {
            const data = labels.map(label => {
              const row = dataSource.rows.find(r => r[0] === dx && r[1] === label);
              return row ? parseFloat(row[3]) : 0;
            });
            const colors = ['#027ad5', '#72cffd', '#34a853', '#fbbc05', '#ea4335', '#4285f4'];
            return {
              label: dataSource.metaData.items[dx]?.name,
              data,
              backgroundColor: colors[index % colors.length],
            };
          });
          chartRefs.current[chartIndex] = chartRefs.current[chartIndex] || React.createRef();
          return { labels, datasets };
        });
      };

      setChartData1(createChartData(data1, dataElements1));
      setChartData2(createChartData(data2, dataElements2));

      const prepareTableDataByYear = () => {
        const provinces = data1.metaData.dimensions.ou.filter(ou => ou !== ''); // Exclude national level
        const tableRows = provinces.map(province => {
          const row = { province: data1.metaData.items[province]?.name || 'Unknown' };
          dataElements1.flat().concat(dataElements2.flat()).forEach(element => {
            const dataRow1 = data1.rows.find(r => r[0] === element && r[1] === selectedYear && r[2] === province);
            const dataRow2 = data2.rows.find(r => r[0] === element && r[1] === selectedYear && r[2] === province);
            const value = dataRow1 ? parseFloat(dataRow1[3]) : dataRow2 ? parseFloat(dataRow2[3]) : '-';
            row[element] = {
              value: value,
              color: getCellColor(element, value),
            };
          });
          return row;
        });
        return tableRows;
      };

      setTableDataByYear(prepareTableDataByYear());
    }
  }, [data, loading, selectedYear]);

  const getCellColor = (elementId, value) => {
    return 'transparent';
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Vaccine Data',
      },
      datalabels: {
        display: true,
        align: 'top',
        color: 'black',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  const handleDownload = (chartRef) => {
    if (chartRef && chartRef.current) {
      const link = document.createElement('a');
      link.href = chartRef.current.toBase64Image();
      link.download = 'chart.png';
      link.click();
    }
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
  };

  const getDataElementValue = (source, elementId) => {
    if (!data || !data[source]) return 'N/A';
    const row = data[source].rows.find(row => row[0] === elementId && row[1] === '2023');
    return row ? parseFloat(row[3]).toLocaleString() : 'N/A';
  };

  const dataBoxes = [
    {
      title: 'Penta 1 Coverage',
      elements: [
        { id: 'nBuU9oPndeV', source: 'data1', label: 'Coverage' },
      ],
      background: 'linear-gradient(to bottom right, #ffbc96, #fc7498)',
      icon: <FontAwesomeIcon icon={faSyringe} className="icon-background" />,
    },
    {
      title: 'OPV 3 Coverage',
      elements: [
        { id: 'rvy2uhxYI3s', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right,  #8cc8f8, #3798e6)',
      icon: <FontAwesomeIcon icon={faSyringe} className="icon-background" />,
    },
    {
      title: 'Pent 3 Coverage',
      elements: [
        { id: 'ZDq9VuFHCYj', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right,  #82d9d1, #34d6bd)',
      icon: <FontAwesomeIcon icon={faSyringe} className="icon-background" />,
    },
    {
      title: 'MCV2 Coverage',
      elements: [
        { id: 'U7hsRVYXgp6', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right,   #f19ff6, #c17efe)',
      icon: <FontAwesomeIcon icon={faSyringe} className="icon-background" />,
    },
    {
      title: 'BCG Coverage',
      elements: [
        { id: 'jNh7rultv7M', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right,  #ffd700, #ffa500)',
      icon: <FontAwesomeIcon icon={faSyringe} className="icon-background" />,
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <>
          <div className="data-boxes-container1">
            {dataBoxes.map((box, index) => (
              <div key={index} className="data-box" style={{ background: box.background, color: 'white', position: 'relative' }}>
                <div className="data-element">
                  <h3>{box.title}</h3>
                  {box.elements.map((element, idx) => (
                    <p key={idx}>
                      {element.label} | {data && data[element.source] ? getDataElementValue(element.source, element.id) : 'N/A'}
                    </p>
                  ))}
                </div>
                <div className="icon-container">{box.icon}</div>
              </div>
            ))}
          </div>
          <div className="charts-container">
            {chartData1.map((chartData, index) => (
              <div className="chart-item" key={`data1-${index}`}>
                <Bar data={chartData} options={options} ref={chartRefs.current[index]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[index])} />
              </div>
            ))}
            {chartData2.map((chartData, index) => (
              <div className="chart-item" key={`data2-${index}`}>
                <Bar data={chartData} options={options} ref={chartRefs.current[index + chartData1.length]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[index + chartData1.length])} />
              </div>
            ))}
          </div>
          <div className="table-container">
            <div className="table-header">
              <div className="year-selector">
                <label>Year</label>
                {data?.data1?.metaData?.dimensions?.pe?.map(year => (
                  <button
                    key={year}
                    className={`year-button ${year === selectedYear ? 'active' : ''}`}
                    onClick={() => handleYearButtonClick(year)}
                  >
                    {year}
                  </button>
                ))}
              </div>
              <div className="table-key">
                <div className="key-item">
                  <span className="key-color" style={{ backgroundColor: '#4e9d2d' }}></span> Achieved
                </div>
                <div className="key-item">
                  <span className="key-color" style={{ backgroundColor: '#ffd301' }}></span> Average
                </div>
                <div className="key-item">
                  <span className="key-color" style={{ backgroundColor: '#cb544d' }}></span> Need to improve
                </div>
              </div>
            </div>
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Province</th>
                  {dataElements1.flat().concat(dataElements2.flat()).map(elementId => (
                    <th key={elementId}>{data?.data1?.metaData?.items[elementId]?.name || data?.data2?.metaData?.items[elementId]?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableDataByYear.map((row, index) => (
                  <tr key={index}>
                    <td>{row.province}</td>
                    {dataElements1.flat().concat(dataElements2.flat()).map(elementId => (
                      <td key={elementId} style={{ textAlign: 'right', backgroundColor: row[elementId].color }}>
                        {row[elementId].value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Vaccine;
