import React, { useContext, useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../Context/dataContext';
import '../styles.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

const Climate = () => {
  const { data, loading } = useContext(DataContext);
  const [chartData, setChartData] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState('IWp9dQGM0bS'); // Set a default province
  const [selectedDisease, setSelectedDisease] = useState('Nk5PmEwUKeS'); // Set a default disease
  const chartRef = useRef(null);

  const climateDataElements = ['VA05qvanuVs', 'gsiW9SgolNd'];
  const diseaseDataElements = {
    IumX9C8BpsA: 'Diarrhea',
    // Nk5PmEwUKeS: 'Dengue - Fever without warning sign',
    // hXTA4Ry7b9J: 'Dengue - Fever with warning sign',
    // UFvPeJ5pN0t: 'Dengue - Severe cases',
  };
  const diseaseDataElementsTotal = ['Nk5PmEwUKeS', 'hXTA4Ry7b9J', 'UFvPeJ5pN0t'];

  useEffect(() => {
    if (!loading && data) {
      const { data4 } = data;

      const prepareChartData = () => {
        const weeks = [...new Set(data4.rows.map(row => row[1]))].sort((a, b) => {
          const [yearA, weekA] = a.match(/(\d+)[^\d]+(\d+)/).slice(1).map(Number);
          const [yearB, weekB] = b.match(/(\d+)[^\d]+(\d+)/).slice(1).map(Number);
          return yearA === yearB ? weekA - weekB : yearA - yearB;
        });

        const predefinedColors = {
          VA05qvanuVs: '#f7a723', // Color for VA05qvanuVs
          gsiW9SgolNd: '#22c1c7', // Color for gsiW9SgolNd
          IumX9C8BpsA: '#498bdc', // Color for IumX9C8BpsA
          Nk5PmEwUKeS: '#d74554', // Color for Nk5PmEwUKeS
          hXTA4Ry7b9J: 'rgba(54, 162, 235, 1)', // Color for hXTA4Ry7b9J
          UFvPeJ5pN0t: 'rgba(255, 206, 86, 1)', // Color for UFvPeJ5pN0t
        };

        // Prepare climate data
        const climateDatasets = climateDataElements.map((elementId) => {
          const data = weeks.map((week) => {
            const row = data4.rows.find(r => r[0] === elementId && r[2] === selectedProvince && r[1] === week);
            return row ? parseFloat(row[3]) : 0;
          });
          return {
            label: data4.metaData.items[elementId]?.name || elementId,
            data,
            borderColor: predefinedColors[elementId],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointRadius: 3, // Hide data points
          };
        });

        // Prepare disease data
        let diseaseDatasets = [];
        if (selectedDisease === 'Nk5PmEwUKeS') {
          const totalDengueData = weeks.map((week) => {
            const sum = diseaseDataElementsTotal.reduce((acc, elementId) => {
              const row = data4.rows.find(r => r[0] === elementId && r[2] === selectedProvince && r[1] === week);
              return acc + (row ? parseFloat(row[3]) : 0);
            }, 0);
            return sum;
          });
          diseaseDatasets = [{
            label: 'Total Dengue Cases',
            data: totalDengueData,
            borderColor: predefinedColors.Nk5PmEwUKeS,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointRadius: 3, // Hide data points
          }];
        } else if (selectedDisease) {
          diseaseDatasets = [{
            label: data4.metaData.items[selectedDisease]?.name || selectedDisease,
            data: weeks.map((week) => {
              const row = data4.rows.find(r => r[0] === selectedDisease && r[2] === selectedProvince && r[1] === week);
              return row ? parseFloat(row[3]) : 0;
            }),
            borderColor: predefinedColors[selectedDisease],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointRadius: 3, // Hide data points
          }];
        }

        setChartData({
          labels: weeks,
          datasets: [...climateDatasets, ...diseaseDatasets],
        });
      };

      prepareChartData();
    }
  }, [data, loading, selectedProvince, selectedDisease]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = chartRef.current.toBase64Image();
    link.download = 'chart.png';
    link.click();
  };

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
  };

  const handleDiseaseChange = (event) => {
    setSelectedDisease(event.target.value);
  };

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <>
          <div className="controls-container"></div>
          <div className="chart-container">
            {chartData && (
              <div className="chart-item">
                <label id="label-chart-select" htmlFor="province">Select Province: </label>
                <select id="province" value={selectedProvince} onChange={handleProvinceChange}>
                  {data?.data4?.metaData?.dimensions?.ou?.map(province => (
                    <option key={province} value={province}>
                      {data?.data4?.metaData?.items[province]?.name}
                    </option>
                  ))}
                </select>
                <label id="label-chart-select" htmlFor="disease">Select Disease: </label>
                <select id="disease" value={selectedDisease} onChange={handleDiseaseChange}>
                  {Object.entries(diseaseDataElements).map(([key, value]) => (
                    <option key={key} value={key}>{value}</option>
                  ))}
                  <option value="Nk5PmEwUKeS">Total Dengue Cases</option>
                </select>
                <Line
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: 'top' },
                      title: { display: true, text: 'Climate and Disease Data' },
                      tooltip: { enabled: true, }, // Tooltip text color
                      datalabels: {
                        display: false, // Disable data labels
                      },
                    },
                    scales: {
                      x: {
                        type: 'category',
                        title: { display: true, text: 'Week' },
                      },
                      y: {
                        beginAtZero: true,
                        title: { display: true, text: 'Value' },
                      },
                    },
                    elements: {
                      point: {
                        radius: 0, // Hide the data points
                      },
                      line: {
                        borderWidth: 2, // Adjust the line thickness if needed
                      },
                    },
                  }}
                  ref={chartRef}
                  height={120}
                />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={handleDownload} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Climate;
