import React, { useContext, useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faVirus, faRibbon, faBaby, faViruses } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../Context/dataContext';
import '../styles.css'; // Import the updated styles.css

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dengue = () => {
  const { data, loading } = useContext(DataContext);
  const [chartData1, setChartData1] = useState(null);
  const [chartData2, setChartData2] = useState(null);
  const [selectedElement, setSelectedElement] = useState('Nk5PmEwUKeS');
  const [tableData, setTableData] = useState([]);
  const [tableDataByYear, setTableDataByYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState('All');
  const [selectedTableYear, setSelectedTableYear] = useState('2024');
  const [selectedProvince, setSelectedProvince] = useState('IWp9dQGM0bS'); // Default to a province
  const [weeklyChartData, setWeeklyChartData] = useState(null);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const weeklyChartRef = useRef(null);

  const dataElements = ['Nk5PmEwUKeS', 'hXTA4Ry7b9J', 'UFvPeJ5pN0t', 'FKU7AKvGgfo'];
  const weeklyDataElements = ['Nk5PmEwUKeS', 'hXTA4Ry7b9J', 'UFvPeJ5pN0t'];

  // Define predefined colors for each data element
  const predefinedColors = {
    'Nk5PmEwUKeS': '#027ad5',
    'hXTA4Ry7b9J': '#72cffd',
    'UFvPeJ5pN0t': '#027ad5',
    'FKU7AKvGgfo': '#72cffd',
  };

  useEffect(() => {
    if (!loading && data) {
      const { data1, data4 } = data;
      const dataElements1 = ['Nk5PmEwUKeS', 'hXTA4Ry7b9J'];
      const dataElements2 = ['UFvPeJ5pN0t', 'FKU7AKvGgfo'];

      // Filter the rows for each set of data elements
      const filteredRows1 = data1.rows.filter(row => dataElements1.includes(row[0]));
      const filteredRows2 = data1.rows.filter(row => dataElements2.includes(row[0]));

      // Prepare the data for the first chart
      const labels1 = [...new Set(filteredRows1.map(row => row[1]))]; // Periods
      const datasets1 = dataElements1.map((dx) => {
        const data = labels1.map(label => {
          const row = filteredRows1.find(r => r[0] === dx && r[1] === label);
          return row ? parseFloat(row[3]) : 0;
        });
        return {
          label: data1.metaData.items[dx].name,
          data,
          backgroundColor: predefinedColors[dx],
        };
      });

      // Prepare the data for the second chart
      const labels2 = [...new Set(filteredRows2.map(row => row[1]))]; // Periods
      const datasets2 = dataElements2.map((dx) => {
        const data = labels2.map(label => {
          const row = filteredRows2.find(r => r[0] === dx && r[1] === label);
          return row ? parseFloat(row[3]) : 0;
        });
        return {
          label: data1.metaData.items[dx].name,
          data,
          backgroundColor: predefinedColors[dx],
        };
      });

      setChartData1({
        labels: labels1,
        datasets: datasets1,
      });

      setChartData2({
        labels: labels2,
        datasets: datasets2,
      });

      // Prepare the table data
      const processTableData = () => {
        const provinces = data1.metaData.dimensions.ou;
        const years = data1.metaData.dimensions.pe;
        const rows = data1.rows.filter(row => row[0] === selectedElement);

        const table = provinces.map(province => {
          const provinceName = data1.metaData.items[province]?.name || 'Unknown';
          const rowData = { province: provinceName };
          years.forEach(year => {
            const row = rows.find(r => r[1] === year && r[2] === province);
            rowData[year] = row ? parseFloat(row[3]) : '-';
          });
          return rowData;
        });

        return table;
      };

      setTableData(processTableData());

      // Prepare table data for provinces as rows and data elements as columns for selected year
      const prepareTableDataByYear = () => {
        const provinces = data1.metaData.dimensions.ou.filter(ou => ou !== ''); // Exclude national level
        const tableRows = provinces.map(province => {
          const row = { province: data1.metaData.items[province]?.name || 'Unknown' };
          dataElements.forEach(element => {
            const dataRow = data1.rows.find(r => r[0] === element && r[1].startsWith(selectedTableYear) && r[2] === province);
            row[element] = dataRow ? { value: parseFloat(dataRow[3]), color: getCellColor(element, parseFloat(dataRow[3])) } : { value: '-', color: 'transparent' };
          });
          return row;
        });
        return tableRows;
      };

      setTableDataByYear(prepareTableDataByYear());

      // Prepare the weekly stacked column chart data
      const prepareWeeklyChartData = () => {
        let filteredRows = data4.rows.filter(row => row[2] === selectedProvince);

        if (selectedYear !== 'All') {
          filteredRows = filteredRows.filter(row => row[1].startsWith(selectedYear));
        }

        const weeks = [...new Set(filteredRows.map(row => row[1]))].sort((a, b) => {
          const [yearA, weekA] = a.match(/(\d+)[^\d]+(\d+)/).slice(1).map(Number);
          const [yearB, weekB] = b.match(/(\d+)[^\d]+(\d+)/).slice(1).map(Number);
          return yearA === yearB ? weekA - weekB : yearA - yearB;
        });

        const datasets = [
          {
            label: 'Fever without warning sign',
            data: weeks.map(week => {
              const row = filteredRows.find(r => r[0] === 'Nk5PmEwUKeS' && r[1] === week);
              return row ? parseFloat(row[3]) : 0;
            }),
            backgroundColor: predefinedColors['Nk5PmEwUKeS'],
          },
          {
            label: 'Fever with warning sign',
            data: weeks.map(week => {
              const row = filteredRows.find(r => r[0] === 'hXTA4Ry7b9J' && r[1] === week);
              return row ? parseFloat(row[3]) : 0;
            }),
            backgroundColor: predefinedColors['hXTA4Ry7b9J'],
          },
          {
            label: 'Severe cases',
            data: weeks.map(week => {
              const row = filteredRows.find(r => r[0] === 'UFvPeJ5pN0t' && r[1] === week);
              return row ? parseFloat(row[3]) : 0;
            }),
            backgroundColor: '#f06e7a',
          },
        ];

        return {
          labels: weeks,
          datasets: datasets,
        };
      };

      setWeeklyChartData(prepareWeeklyChartData());
    }
  }, [data, loading, selectedElement, selectedYear, selectedProvince, selectedTableYear]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Dengue Data',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  const weeklyChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        display: false, // Disable data labels
      },
      title: {
        display: true,
        text: 'Weekly Dengue Cases by Province',
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Week',
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  const handleDownload = (chartRef) => {
    const link = document.createElement('a');
    link.href = chartRef.current.toBase64Image();
    link.download = 'chart.png';
    link.click();
  };

  const handleElementChange = (event) => {
    setSelectedElement(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleTableYearChange = (event) => {
    setSelectedTableYear(event.target.value);
  };

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
  };

  const getDataElementValue = (source, elementId) => {
    if (!data || !data[source]) return 'N/A';
    const row = data[source].rows.find(row => row[0] === elementId && row[1] === '2024');
    return row ? parseFloat(row[3]).toLocaleString() : 'N/A';
  };

  const getCellColor = (elementId, value) => {
    return 'transparent';
  };

  const dataBoxes = [
    {
      title: 'Fever without warning sign',
      elements: [
        { id: 'Nk5PmEwUKeS', source: 'data1', label: 'Tested' },
      ],
      background: 'linear-gradient(to bottom right, #ffbc96, #fc7498)',
      icon: <FontAwesomeIcon icon={faRibbon} className="icon-background" />,
    },
    {
      title: 'Fever with warning sign',
      elements: [
        { id: 'hXTA4Ry7b9J', source: 'data1', label: 'Cases' }
      ],
      background: 'linear-gradient(to bottom right, #8cc8f8, #3798e6)',
      icon: <FontAwesomeIcon icon={faVirus} className="icon-background" />,
    },
    {
      title: 'Severe cases',
      elements: [
        { id: 'UFvPeJ5pN0t', source: 'data1', label: 'Positive' }
      ],
      background: 'linear-gradient(to bottom right, #82d9d1, #34d6bd)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
    {
      title: 'Deaths',
      elements: [
        { id: 'FKU7AKvGgfo', source: 'data1', label: 'Deaths' }
      ],
      background: 'linear-gradient(to bottom right,  #f19ff6, #c17efe)',
      icon: <FontAwesomeIcon icon={faBaby} className="icon-background" />,
    },
  ];

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
  };

  const handleTableYearButtonClick = (year) => {
    setSelectedTableYear(year);
  };

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <>
          <div className="data-boxes-container1">
            {dataBoxes.map((box, index) => (
              <div key={index} className="data-box" style={{ background: box.background, color: 'white', position: 'relative' }}>
                <div className="data-element">
                  <h3>{box.title}</h3>
                  {box.elements.map((element, idx) => (
                    <p key={idx}>
                      {element.label} | {getDataElementValue(element.source, element.id)}
                    </p>
                  ))}
                </div>
                <div className="icon-container">{box.icon}</div>
              </div>
            ))}
          </div>
          <div className="charts-container">
            {chartData1 && (
              <div className="chart-item">
                <Bar data={chartData1} options={options} ref={chartRef1} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRef1)} />
              </div>
            )}
            {chartData2 && (
              <div className="chart-item">
                <Bar data={chartData2} options={options} ref={chartRef2} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRef2)} />
              </div>
            )}
          </div>
          <div className="dashboard-container-1">
            <div className="chart-item">
              <div className="filter-container">
                <label id="label-chart-select" htmlFor="province">Select Province: </label>
                <select id="province" value={selectedProvince} onChange={handleProvinceChange}>
                  {data?.data4?.metaData?.dimensions?.ou?.map(province => (
                    <option key={province} value={province}>
                      {data?.data4?.metaData?.items[province]?.name}
                    </option>
                  ))}
                </select>
                <div className="year-selector">
                  <button
                    className={`year-button ${selectedYear === 'All' ? 'active' : ''}`}
                    onClick={() => handleYearButtonClick('All')}
                  >
                    All
                  </button>
                  {[...new Set(data?.data4?.metaData?.dimensions?.pe.map(year => year.slice(0, 4)))].sort().map(yearOnly => (
                    <button
                      key={yearOnly}
                      className={`year-button ${yearOnly === selectedYear ? 'active' : ''}`}
                      onClick={() => handleYearButtonClick(yearOnly)}
                    >
                      {yearOnly}
                    </button>
                  ))}
                </div>
              </div>
              {weeklyChartData && (
                <div className="">
                  <Bar data={weeklyChartData} options={weeklyChartOptions} ref={weeklyChartRef} height={100} />
                  <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(weeklyChartRef)} />
                </div>
              )}
            </div>
          </div>
          <div className="table-container">
            <div className="year-selector">
              <label>Year  </label>
              {[...new Set(data?.data1?.metaData?.dimensions?.pe.map(year => year.slice(0, 4)))].sort().map(yearOnly => (
                <button
                  key={yearOnly}
                  className={`year-button ${yearOnly === selectedTableYear ? 'active' : ''}`}
                  onClick={() => handleTableYearButtonClick(yearOnly)}
                >
                  {yearOnly}
                </button>
              ))}
            </div>
            <h3></h3>
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Province</th>
                  {dataElements.map(elementId => (
                    <th key={elementId}>{data?.data1?.metaData?.items[elementId]?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableDataByYear.map((row, index) => (
                  <tr key={index}>
                    <td>{row.province}</td>
                    {dataElements.map(elementId => (
                      <td key={elementId} style={{ textAlign: 'right', backgroundColor: row[elementId].color }}>
                        {row[elementId].value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <select onChange={handleElementChange} value={selectedElement}>
              <option value="Nk5PmEwUKeS">Fever without warning sign</option>
              <option value="hXTA4Ry7b9J">Fever with warning sign</option>
              <option value="UFvPeJ5pN0t">Severe cases</option>
              <option value="FKU7AKvGgfo">Deaths</option>
            </select>
            <h3>{data && data.data1.metaData.items[selectedElement].name}</h3>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Province</th>
                  {data && data.data1.metaData.dimensions.pe.map(year => (
                    <th key={year} >{year}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.province}</td>
                    {data && data.data1.metaData.dimensions.pe.map(year => (
                      <td key={year} style={{ textAlign: 'right' }}>{row[year]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Dengue;
