import React, { useState } from 'react';
import populationImage from '../img/population.png'; // Import the image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

const populationData = {
  '2019': [
    { province: 'Lao PDR', population: 7123197 },
    { province: 'Vientiane Capital', population: 927730 },
    { province: 'Phongsali', population: 191531 },
    { province: 'Louangnamtha', population: 195767 },
    { province: 'Oudomxai', population: 340086 },
    { province: 'Bokeo', population: 200100 },
    { province: 'Louangphabang', population: 463197 },
    { province: 'Houaphan', population: 308641 },
    { province: 'Xainyabouli', population: 417782 },
    { province: 'Xiangkhouang', population: 264504 },
    { province: 'Vientiane', population: 456372 },
    { province: 'Bolikhamxai', population: 309460 },
    { province: 'Khammouan', population: 427382 },
    { province: 'Savannakhet', population: 1053953 },
    { province: 'Salavan', population: 434671 },
    { province: 'Xekong', population: 126998 },
    { province: 'Champasak', population: 743215 },
    { province: 'Attapu', population: 156836 },
    { province: 'Xaisomboun', population: 104972 },
  ],
  '2020': [
    { province: 'Lao PDR', population: 7231103 },
    { province: 'Vientiane Capital', population: 948439 },
    { province: 'Phongsali', population: 193175 },
    { province: 'Louangnamtha', population: 199098 },
    { province: 'Oudomxai', population: 345429 },
    { province: 'Bokeo', population: 203479 },
    { province: 'Louangphabang', population: 467103 },
    { province: 'Houaphan', population: 310955 },
    { province: 'Xainyabouli', population: 423468 },
    { province: 'Xiangkhouang', population: 267164 },
    { province: 'Vientiane', population: 462103 },
    { province: 'Bolikhamxai', population: 314964 },
    { province: 'Khammouan', population: 433569 },
    { province: 'Savannakhet', population: 1070010 },
    { province: 'Salavan', population: 442264 },
    { province: 'Xekong', population: 129401 },
    { province: 'Champasak', population: 752667 },
    { province: 'Attapu', population: 159896 },
    { province: 'Xaisomboun', population: 107919 },
  ],
  '2021': [
    { province: 'Lao PDR', population: 7337714 },
    { province: 'Vientiane Capital', population: 968996 },
    { province: 'Phongsali', population: 194691 },
    { province: 'Louangnamtha', population: 202399 },
    { province: 'Oudomxai', population: 350657 },
    { province: 'Bokeo', population: 206781 },
    { province: 'Louangphabang', population: 470843 },
    { province: 'Houaphan', population: 313147 },
    { province: 'Xainyabouli', population: 428964 },
    { province: 'Xiangkhouang', population: 269714 },
    { province: 'Vientiane', population: 467869 },
    { province: 'Bolikhamxai', population: 320364 },
    { province: 'Khammouan', population: 439645 },
    { province: 'Savannakhet', population: 1086024 },
    { province: 'Salavan', population: 449806 },
    { province: 'Xekong', population: 131836 },
    { province: 'Champasak', population: 762150 },
    { province: 'Attapu', population: 162938 },
    { province: 'Xaisomboun', population: 110890 },
  ],
  '2022': [
    { province: 'Lao PDR', population: 7442732 },
    { province: 'Vientiane Capital', population: 989292 },
    { province: 'Phongsali', population: 196168 },
    { province: 'Louangnamtha', population: 205650 },
    { province: 'Oudomxai', population: 355786 },
    { province: 'Bokeo', population: 210055 },
    { province: 'Louangphabang', population: 474348 },
    { province: 'Houaphan', population: 315193 },
    { province: 'Xainyabouli', population: 434293 },
    { province: 'Xiangkhouang', population: 272115 },
    { province: 'Vientiane', population: 473631 },
    { province: 'Bolikhamxai', population: 325625 },
    { province: 'Khammouan', population: 445562 },
    { province: 'Savannakhet', population: 1101857 },
    { province: 'Salavan', population: 457338 },
    { province: 'Xekong', population: 134283 },
    { province: 'Champasak', population: 771704 },
    { province: 'Attapu', population: 166008 },
    { province: 'Xaisomboun', population: 113824 },
  ],
  '2023': [
    { province: 'Lao PDR', population: 7545667 },
    { province: 'Vientiane Capital', population: 1009287 },
    { province: 'Phongsali', population: 197569 },
    { province: 'Louangnamtha', population: 208848 },
    { province: 'Oudomxai', population: 360811 },
    { province: 'Bokeo', population: 213271 },
    { province: 'Louangphabang', population: 477651 },
    { province: 'Houaphan', population: 317049 },
    { province: 'Xainyabouli', population: 439382 },
    { province: 'Xiangkhouang', population: 274365 },
    { province: 'Vientiane', population: 479372 },
    { province: 'Bolikhamxai', population: 330744 },
    { province: 'Khammouan', population: 451329 },
    { province: 'Savannakhet', population: 1117489 },
    { province: 'Salavan', population: 464847 },
    { province: 'Xekong', population: 136746 },
    { province: 'Champasak', population: 781136 },
    { province: 'Attapu', population: 169010 },
    { province: 'Xaisomboun', population: 116761 },
  ],
  '2024': [
    { province: 'Lao PDR', population: 7646564 },
    { province: 'Vientiane Capital', population: 1028919 },
    { province: 'Phongsali', population: 198904 },
    { province: 'Louangnamtha', population: 212020 },
    { province: 'Oudomxai', population: 365695 },
    { province: 'Bokeo', population: 216405 },
    { province: 'Louangphabang', population: 480746 },
    { province: 'Houaphan', population: 318768 },
    { province: 'Xainyabouli', population: 444300 },
    { province: 'Xiangkhouang', population: 276427 },
    { province: 'Vientiane', population: 485076 },
    { province: 'Bolikhamxai', population: 335726 },
    { province: 'Khammouan', population: 456934 },
    { province: 'Savannakhet', population: 1132886 },
    { province: 'Salavan', population: 472291 },
    { province: 'Xekong', population: 139212 },
    { province: 'Champasak', population: 790563 },
    { province: 'Attapu', population: 171973 },
    { province: 'Xaisomboun', population: 119719 },
  ]
};

const ProvincialPopulationTable = () => {
  const [selectedYear, setSelectedYear] = useState('2024'); // Default year

  const malePercentage = 0.67; // 67% for male
  const femalePercentage = 0.70; // 70% for female

  return (
    <div className="province-table-container" style={{ display: 'flex', gap: '20px' }}>
      <div className="left-column" style={{ width: '40%' }}>
        <div className="titles-container">
          <h4>Life Expectancy</h4>
        </div>
        <div className="life-expectancy">
          <div className="gauge-item male">
            <FontAwesomeIcon icon={faMale} />
            <div className="gauge-label">67</div>
            <div className="gauge-bar">
              <div className="gauge-fill male" style={{ width: `${malePercentage * 100}%` }}></div>
            </div>
          </div>
          <div className="gauge-item female">
            <FontAwesomeIcon icon={faFemale} />
            <div className="gauge-label">70</div>
            <div className="gauge-bar">
              <div className="gauge-fill female" style={{ width: `${femalePercentage * 100}%` }}></div>
            </div>
          </div>
        </div>
        <img src={populationImage} alt="Population" className="population-image" style={{ width: '100%', marginTop: '20px' }} />
      </div>
      <div className="right-column" style={{ width: '60%' }}>
        <div className="year-selector1">
          {['2019', '2020', '2021', '2022', '2023','2024'].map((year) => (
            <button
              key={year}
              className={`year-button1 ${selectedYear === year ? 'active' : ''}`}
              onClick={() => setSelectedYear(year)}
              style={{ marginRight: '5px' }}
            >
              {year}
            </button>
          ))}
        </div>
        <table className="custom-table" style={{ width: '100%', marginTop: '20px' }}>
          <thead>
            <tr>
              <th>Province</th>
              <th>Population</th>
            </tr>
          </thead>
          <tbody>
            {populationData[selectedYear].map((province, index) => (
              <tr key={index}>
                <td>{province.province}</td>
                <td style={{ textAlign: 'right' }}>{province.population.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProvincialPopulationTable;
