import React from 'react';
import './styles.css';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import MainContent from './components/MainContent';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DataProvider } from './Context/dataContext';

const App = () => {
  return (
    <DataProvider>
      <Router>
        <div className="App">
          <Header />
          <Sidebar />
          <div className="main-content">
            <Routes>
              <Route path="/dengue" element={<MainContent selectedTab="dengue" />} />
              <Route path="/leprosy" element={<MainContent selectedTab="leprosy" />} />
              <Route path="/hiv" element={<MainContent selectedTab="hiv" />} />
              <Route path="/" element={<MainContent selectedTab="dashboard" />} />
              <Route path="/malaria" element={<MainContent selectedTab="malaria" />} />
              <Route path="/vaccine" element={<MainContent selectedTab="vaccine" />} /> 
              <Route path="/mch" element={<MainContent selectedTab="mch" />} />
              <Route path="/tb" element={<MainContent selectedTab="tb" />} />
              <Route path="/climate" element={<MainContent selectedTab="climate" />} />
              {/* Add other routes as needed */}
            </Routes>
          </div>
        </div>
      </Router>
    </DataProvider>
  );
};

export default App;
