import React, { useContext, useEffect, useState, useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faRibbon, faBaby, faViruses } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../Context/dataContext';
import '../styles.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Mch = () => {
  const { data, loading } = useContext(DataContext);
  const [chartData, setChartData] = useState([]);
  const [tableDataByYear, setTableDataByYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2024');
  const [lineData, setLineData] = useState(null);
  const chartRefs = useRef([]);

  const dataElements = [
    'MB9Y4omDJ9C','kJQjz2RsnAD', 'ydkXWY0jg48', 'h8KiBz2888q', 'J5E8BkAoIWn','PGaxoLVKaCz', 'DYJX09D1lPL', 'CyUEAFiwGTS.eFXrDqXA2WQ', 'CyUEAFiwGTS.BaKsAyoQBud',
  ];

  useEffect(() => {
    if (!loading && data) {
      const { data1 } = data;

      const createChartData = (data1, elements) => {
        const labels = [...new Set(data1.rows.map(row => row[1]))]; // Periods
        return elements.map((dx) => {
          const data = labels.map(label => {
            const row = data1.rows.find(r => r[0] === dx && r[1] === label);
            return row ? parseFloat(row[3]) : 0;
          });
          const metaDataItem = data1.metaData.items[dx];
          return {
            label: metaDataItem ? metaDataItem.name : `Unknown (${dx})`,
            data,
            backgroundColor: elements.length === 1 ? '#027ad5' : ['#027ad5', '#72cffd'],
          };
        });
      };

      const allChartData = dataElements.map((element, index) => {
        const chartData = createChartData(data1, [element]);
        chartRefs.current[index] = React.createRef(); // Initialize the ref for each chart
        return {
          labels: [...new Set(data1.rows.map(row => row[1]))], // Periods
          datasets: chartData,
        };
      });

      setChartData(allChartData);

      // Prepare table data for provinces as rows and data elements as columns for selected year
      const prepareTableDataByYear = () => {
        const provinces = data1.metaData.dimensions.ou.filter(ou => ou !== ''); // Exclude national level
        const tableRows = provinces.map(province => {
          const row = { province: data1.metaData.items[province]?.name || 'Unknown' };
          dataElements.forEach(element => {
            const dataRow = data1.rows.find(r => r[0] === element && r[1] === selectedYear && r[2] === province);
            row[element] = dataRow ? { value: parseFloat(dataRow[3]), color: getCellColor(element, parseFloat(dataRow[3])) } : { value: '-', color: 'transparent' };
          });
          return row;
        });
        return tableRows;
      };

      setTableDataByYear(prepareTableDataByYear());

      // Process Line Data for Mortality
      const processLineData = (data1, elementIds) => {
        const labels = data1.metaData.dimensions.pe.map(period => data1.metaData.items[period]?.name || period);
        const datasets = elementIds.map(elementId => {
          const dataValues = data1.metaData.dimensions.pe.map(period => {
            const row = data1.rows.find(row => row[0] === elementId && row[1] === period);
            return row ? parseFloat(row[3]) : 0;
          });
          return {
            label: data1.metaData.items[elementId]?.name || elementId,
            data: dataValues,
            borderColor: ['#ae1857', '#ffb71b', '#4bc0c0', '#194f90'],
            backgroundColor: ['#ae1857', '#ffb71b', '#4bc0c0', '#194f90'],
            fill: false
          };
        });

        return {
          labels,
          datasets
        };
      };

      const mortalityData = processLineData(data1, ['Yp9NxO9NCly', 'OjUHkTCv6cd', 'ZGuni8UTDTK', 'U2Tmx7kjSzc']);
      setLineData(mortalityData);
    }
  }, [data, loading, selectedYear]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getCellColor = (elementId, value) => {
    return 'transparent';
  };

  const getDataElementValue = (source, elementId) => {
    if (!data || !data[source]) return 'N/A';
    const row = data[source].rows.find(row => row[0] === elementId && row[1] === '2023');
    return row ? parseFloat(row[3]).toLocaleString() : 'N/A';
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'MCH Data',
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => value.toLocaleString(),
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  const handleDownload = (chartRef) => {
    if (chartRef && chartRef.current) {
      const link = document.createElement('a');
      link.href = chartRef.current.toBase64Image();
      link.download = 'chart.png';
      link.click();
    }
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
  };

  const dataBoxes = [
    {
      title: 'ANC 1 Coverage',
      elements: [
        { id: 'kJQjz2RsnAD', source: 'data1', label: 'Coverage' },
      ],
      background: 'linear-gradient(to bottom right, #ffbc96, #fc7498)',
      icon: <FontAwesomeIcon icon={faRibbon} className="icon-background" />,
    },
    {
      title: 'ANC 4 Coverage',
      elements: [
        { id: 'ydkXWY0jg48', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right, #8cc8f8, #3798e6)',
      icon: <FontAwesomeIcon icon={faBaby} className="icon-background" />,
    },
    {
      title: 'ANC1 - ANC4 Dropout',
      elements: [
        { id: 'MB9Y4omDJ9C', source: 'data1', label: 'Number' }
      ],
      background: 'linear-gradient(to bottom right, #82d9d1, #34d6bd)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
    {
      title: 'SBA Coverage',
      elements: [
        { id: 'DYJX09D1lPL', source: 'data1', label: 'Coverage' }
      ],
      background: 'linear-gradient(to bottom right, #f19ff6, #c17efe)',
      icon: <FontAwesomeIcon icon={faBaby} className="icon-background" />,
    },
    {
      title: 'Vitamin A',
      elements: [
        { id: 'CyUEAFiwGTS.eFXrDqXA2WQ', source: 'data1', label: 'Supplementation' }
      ],
      background: 'linear-gradient(to bottom right, #ffd700, #ffa500)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
    
  ];

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <>
          <div className="data-boxes-container1">
            {dataBoxes.map((box, index) => (
              <div key={index} className="data-box" style={{ background: box.background, color: 'white', position: 'relative' }}>
                <div className="data-element">
                  <h3>{box.title}</h3>
                  {box.elements.map((element, idx) => (
                    <p key={idx}>
                      {element.label} | {data && data[element.source] ? getDataElementValue(element.source, element.id) : 'N/A'}
                    </p>
                  ))}
                </div>
                <div className="icon-container">{box.icon}</div>
              </div>
            ))}
          </div>
          <div className="charts-container">
            {chartData.map((chart, index) => (
              <div className="chart-item" key={index}>
                <Bar data={chart} options={options} ref={chartRefs.current[index]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[index])} />
              </div>
            ))}
          </div>
          {lineData && (
            <div className="horizontal-container">
              <div className="chart-item">
                <h3>Mortality</h3>
                <Line
                  data={lineData}
                  options={{
                    responsive: true,
                    scales: {
                      y: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          text: 'Value',
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
          <div className="table-container">
            <div className="year-selector">
              <label>Year  </label>
              {data?.data1?.metaData?.dimensions?.pe?.map(year => (
                <button
                  key={year}
                  className={`year-button ${year === selectedYear ? 'active' : ''}`}
                  onClick={() => handleYearButtonClick(year)}
                >
                  {year}
                </button>
              ))}
            </div>
            <h3></h3>
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Province</th>
                  {dataElements.map(elementId => (
                    <th key={elementId}>{data?.data1?.metaData?.items[elementId]?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableDataByYear.map((row, index) => (
                  <tr key={index}>
                    <td>{row.province}</td>
                    {dataElements.map(elementId => (
                      <td key={elementId} style={{ textAlign: 'right', backgroundColor: row[elementId].color }}>
                        {row[elementId].value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Mch;
