import React from 'react';
import { useLocation } from 'react-router-dom';
import Dengue from './Dengue';
import Leprosy from './Leprosy';
import Dashboard from './Dashboard';
import Malaria from './Malaria';
import HIV from './Hiv';
import Vaccine from './Vaccine'; 
import Mch from './Mch';
import Tb from './Tb';
import Climate from './Climate';
import '../styles.css';

const MainContent = () => {
  const location = useLocation();

  const renderContent = () => {
    switch (location.pathname) {
      case '/dengue':
        return <Dengue />;
      case '/leprosy':
        return <Leprosy />;
      case '/hiv':
        return <HIV />;
      case '/malaria':
        return <Malaria />;
      case '/vaccine': 
        return <Vaccine />;
      case '/mch':
        return <Mch />;
      case '/tb':
        return <Tb />;
            case '/tb':
        return <Tb />;
        case '/climate':
          return <Climate />;
        case '/':
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="main-content">
      {renderContent()}
    </div>
  );
};

export default MainContent;
