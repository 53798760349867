import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faVirus, faSyringe, faUserMd, faBaby, faVial, faViruses, faRibbon, faMosquito, faPerson, faCloud, faBook, faPersonBreastfeeding, faHeartBroken, faHeadSideCough } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const location = useLocation();

  return (
    <nav className="sidebar">
      <ul>
        <li className={location.pathname === '/' ? 'active' : ''}>
          <Link to="/">
            <FontAwesomeIcon icon={faHome} /> <span>Home</span>
          </Link>
        </li>
        <li className={location.pathname === '/mch' ? 'active' : ''}>
          <Link to="/mch">
            <FontAwesomeIcon icon={faPersonBreastfeeding} /> <span>MCH</span>
          </Link>
        </li>
        <li className={location.pathname === '/vaccine' ? 'active' : ''}>
          <Link to="/vaccine">
            <FontAwesomeIcon icon={faSyringe} /> <span>Vaccine</span>
          </Link>
        </li>
        <li className={location.pathname === '/hiv' ? 'active' : ''}>
          <Link to="/hiv">
            <FontAwesomeIcon icon={faRibbon} /> <span>HIV</span>
          </Link>
        </li>
        <li className={location.pathname === '/tb' ? 'active' : ''}>
          <Link to="/tb">
            <FontAwesomeIcon icon={faHeadSideCough} /> <span>TB</span>
          </Link>
        </li>
        <li className={location.pathname === '/malaria' ? 'active' : ''}>
          <Link to="/malaria">
            <FontAwesomeIcon icon={faMosquito} /> <span>Malaria</span>
          </Link>
        </li>
        <li className={location.pathname === '/dengue' ? 'active' : ''}>
          <Link to="/dengue">
            <FontAwesomeIcon icon={faMosquito} /> <span>Dengue</span>
          </Link>
        </li>
        <li className={location.pathname === '/climate' ? 'active' : ''}>
          <Link to="/climate">
            <FontAwesomeIcon icon={faCloud} /> <span>Climate</span>
          </Link>
        </li>
        <li className={location.pathname === '/leprosy' ? 'active' : ''}>
          <Link to="/leprosy">
            <FontAwesomeIcon icon={faPerson} /> <span>Leprosy</span>
          </Link>
        </li>
        <li>
          <a href="../laoDataDictionary/index.html" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faBook} /> <span>Data Dictionary</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
