import React, { useContext, useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faViruses } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../Context/dataContext';
import '../styles.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Tb = () => {
  const { data, loading } = useContext(DataContext);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [tableDataByYear, setTableDataByYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2024');
  const chartRefs = useRef([]);

  const dataElements1 = [
    ['mXYcr3mHxyN', 'O9BTmjhggwk', 'kQXyBx4PoSy'],
  ];

  const dataElements2 = [
    ['gdkuK2eNEeK', 'khs4yQnJVjb', 'xIfpyFAGKOi'],
    ['J7zy5K1WENL', 'Rxtc73uzQl8', 'XXKXGQsgU99', 'wCYNUiGOAH0', 'r0VoU6w8tgp', 'cSwBxr0ksAb', 'Q77JADnLzO8', 'dD7ZhzexjwC'],
  ];

  const themeColor1 = '#027ad5'; // First theme color
  const themeColor2 = '#72cffd'; // Second theme color

  useEffect(() => {
    if (!loading && data) {
      const { data1, data2 } = data;

      const createChartData = (dataSource, dataElements) => {
        return dataElements.map((elements, chartIndex) => {
          let labels = [...new Set(dataSource.rows.map(row => row[1]))];
          labels.sort(); // Ensure the labels (years) are in ascending order
          const datasets = elements.map((dx, index) => {
            const data = labels.map(label => {
              const row = dataSource.rows.find(r => r[0] === dx && r[1] === label);
              return row ? parseFloat(row[3]) : 0;
            });
            const backgroundColor = elements.length > 1 ? (index % 2 === 0 ? themeColor1 : themeColor2) : themeColor1;
            return {
              label: dataSource.metaData.items[dx]?.name,
              data,
              backgroundColor,
              borderColor: backgroundColor,
            };
          });
          chartRefs.current[chartIndex] = chartRefs.current[chartIndex] || React.createRef();
          return { labels, datasets };
        });
      };

      setChartData1(createChartData(data1, dataElements1));
      setChartData2(createChartData(data2, dataElements2));

      const prepareTableDataByYear = () => {
        const provinces = data1.metaData.dimensions.ou.filter(ou => ou !== ''); // Exclude national level
        const tableRows = provinces.map(province => {
          const row = { province: data1.metaData.items[province]?.name || 'Unknown' };
          dataElements1.flat().concat(dataElements2.flat()).forEach(element => {
            const dataRow1 = data1.rows.find(r => r[0] === element && r[1] === selectedYear && r[2] === province);
            const dataRow2 = data2.rows.find(r => r[0] === element && r[1] === selectedYear && r[2] === province);
            row[element] = dataRow1 ? { value: parseFloat(dataRow1[3]) } : dataRow2 ? { value: parseFloat(dataRow2[3]) } : { value: '-' };
          });
          return row;
        });
        return tableRows;
      };

      setTableDataByYear(prepareTableDataByYear());
    }
  }, [data, loading, selectedYear]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'TB Data',
      },
      datalabels: {
        display: true,
        align: 'top',
        color: 'black',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  const handleDownload = (chartRef) => {
    if (chartRef && chartRef.current) {
      const link = document.createElement('a');
      link.href = chartRef.current.toBase64Image();
      link.download = 'chart.png';
      link.click();
    }
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
  };

  // Extracting 2023 data elements for the top data box
  const getDataElementValue = (dataSource, elementId) => {
    const row = dataSource.rows.find(row => row[0] === elementId && row[1] === '2023');
    return row ? parseFloat(row[3]).toLocaleString() : 'N/A';
  };

  const dataBoxes = [
    {
      title: 'Genexpert Test',
      elements: [
        { id: 'mXYcr3mHxyN', source: 'data1', label: 'Notified' },
      ],
      background: 'linear-gradient(to bottom right, #ffbc96, #fc7498)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
    {
      title: 'Microscopy Tested',
      elements: [
        { id: 'O9BTmjhggwk', source: 'data1', label: 'Confirmed' }
      ],
      background: 'linear-gradient(to bottom right, #8cc8f8, #3798e6)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
    {
      title: 'TB Deaths',
      elements: [
        { id: 'gdkuK2eNEeK', source: 'data2', label: 'Deaths' }
      ],
      background: 'linear-gradient(to bottom right, #82d9d1, #34d6bd)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
    {
      title: 'Treatment Success',
      elements: [
        { id: 'khs4yQnJVjb', source: 'data2', label: 'Success' }
      ],
      background: 'linear-gradient(to bottom right,  #f19ff6, #c17efe)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
    {
      title: 'HIV+ TB Cases',
      elements: [
        { id: 'xIfpyFAGKOi', source: 'data2', label: 'HIV+' }
      ],
      background: 'linear-gradient(to bottom right, #ffd700, #ffa500)',
      icon: <FontAwesomeIcon icon={faViruses} className="icon-background" />,
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <>
          <div className="data-boxes-container1">
            {dataBoxes.map((box, index) => (
              <div key={index} className="data-box" style={{ background: box.background, color: 'white', position: 'relative' }}>
                <div className="data-element">
                  <h3>{box.title}</h3>
                  {box.elements.map((element, idx) => (
                    <p key={idx}>
                      {element.label} | {data && data[element.source] ? getDataElementValue(data[element.source], element.id) : 'N/A'}
                    </p>
                  ))}
                </div>
                <div className="icon-container">{box.icon}</div>
              </div>
            ))}
          </div>
          <div className="charts-container">
            {chartData1.map((chartData, index) => (
              <div className="chart-item" key={`data1-${index}`}>
                <Bar data={chartData} options={options} ref={chartRefs.current[index]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[index])} />
              </div>
            ))}
            {chartData2.map((chartData, index) => (
              <div className="chart-item" key={`data2-${index}`}>
                <Bar data={chartData} options={options} ref={chartRefs.current[index + chartData1.length]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[index + chartData1.length])} />
              </div>
            ))}
          </div>
          <div className="table-container">
            <div className="year-selector">
              <label>Year</label>
              {data?.data1?.metaData?.dimensions?.pe?.map(year => (
                <button
                  key={year}
                  className={`year-button ${year === selectedYear ? 'active' : ''}`}
                  onClick={() => handleYearButtonClick(year)}
                >
                  {year}
                </button>
              ))}
            </div>
            <h3></h3>
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Province</th>
                  {dataElements1.flat().concat(dataElements2.flat()).map(elementId => (
                    <th key={elementId}>{data?.data1?.metaData?.items[elementId]?.name || data?.data2?.metaData?.items[elementId]?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableDataByYear.map((row, index) => (
                  <tr key={index}>
                    <td>{row.province}</td>
                    {dataElements1.flat().concat(dataElements2.flat()).map(elementId => (
                      <td key={elementId} style={{ textAlign: 'right' }}>
                        {row[elementId].value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Tb;
