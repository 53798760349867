import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css'; // Make sure you have the updated styles
import logo from '../img/World-Health-Organization-WHO-Logo.png'; // Correctly import the image

const Header = () => {
  return (
    <header className="header">
      <h3>Dashboard Lao PDR</h3>
      <Link to="/">
        <img src={logo} alt="WHO Logo" className="logo" />
      </Link>
    </header>
  );
};

export default Header;
