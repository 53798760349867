import React, { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { DataContext } from '../Context/dataContext';
import '../styles.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Leprosy = () => {
  const { data, loading } = useContext(DataContext);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!loading && data && data.data1) {
      const { data1 } = data;
      const dataElements = ['IpRjFeKBCA7'];

      // Filter the rows for Leprosy data elements
      const filteredRows = data1.rows.filter(row => dataElements.includes(row[0]));

      // Prepare the data for the chart
      const labels = [...new Set(filteredRows.map(row => row[1]))]; // Periods
      const datasets = dataElements.map((dx) => {
        const data = labels.map(label => {
          const row = filteredRows.find(r => r[0] === dx && r[1] === label);
          return row ? parseFloat(row[3]) : 0;
        });
        return {
          label: data1.metaData.items[dx].name,
          data,
          borderColor: getRandomColor(),
          fill: false,
        };
      });

      setChartData({
        labels,
        datasets,
      });
    }
  }, [data, loading]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Leprosy Data',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <div className="charts-container">
        <div className="chart-item">
          {chartData ? (
            <Line data={chartData} options={options} />
          ) : (
            <p>Data not available</p>
          )}
        </div> </div>
      )}
    </div>
  );
};

export default Leprosy;
