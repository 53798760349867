import React, { useContext, useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faRibbon } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../Context/dataContext';
import '../styles.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HIV = () => {
  const { data, loading } = useContext(DataContext);
  const [chartData1, setChartData1] = useState(null);
  const [chartData2, setChartData2] = useState(null);
  const [chartData3, setChartData3] = useState(null);
  const [chartData4, setChartData4] = useState(null);
  const [tableDataByYear, setTableDataByYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2024');
  const chartRefs = useRef([useRef(null), useRef(null), useRef(null), useRef(null)]);

  const dataElements = ['GwfwBw4iwnY', 't0wTKNT7BoP', 'tikqFtKTzlR', 'caaHNZGmlHO', 'KWvSOcG0CuL', 'Aa18Fhm2qTf', 'nEECKCxgoV5', 'pZMipf3xpGM', 'AtobL1cQ4Qc', 'SghlO61QCNL', 'CI16RQqu8Ri', 'CJlcg6qvgT0', 'bW0Rgj4ctQ7', 'xAbxZEHkajc', 'Pyh8mz9M63A', 'zLyH8VcPWzX', 'rrnRosB1MmQ', 'w25OCI71Sdc', 'u9lVdGbN4OP', 'bzZgzSWBvbu', 'BEPCKBJ0t6t'];

  const themeColors = ['#027ad5', '#72cffd', '#34a853', '#fbbc05', '#ea4335', '#4285f4']; // Base theme colors

  useEffect(() => {
    if (!loading && data) {
      const { data1 } = data;
      const dataElements1 = ['GwfwBw4iwnY', 't0wTKNT7BoP'];
      const dataElements2 = ['tikqFtKTzlR', 'caaHNZGmlHO', 'KWvSOcG0CuL', 'Aa18Fhm2qTf', 'nEECKCxgoV5', 'pZMipf3xpGM', 'AtobL1cQ4Qc', 'SghlO61QCNL', 'CI16RQqu8Ri', 'CJlcg6qvgT0', 'bW0Rgj4ctQ7'];
      const dataElements3 = ['xAbxZEHkajc', 'Pyh8mz9M63A', 'CI16RQqu8Ri', 'zLyH8VcPWzX', 'rrnRosB1MmQ', 'w25OCI71Sdc', 'u9lVdGbN4OP', 'bzZgzSWBvbu'];
      const dataElements4 = ['BEPCKBJ0t6t'];

      // Filter the rows for each set of data elements
      const filteredRows1 = data1.rows.filter(row => dataElements1.includes(row[0]));
      const filteredRows2 = data1.rows.filter(row => dataElements2.includes(row[0]) && row[1] === '2023');
      const filteredRows3 = data1.rows.filter(row => dataElements3.includes(row[0]) && row[1] === '2023');
      const filteredRows4 = data1.rows.filter(row => dataElements4.includes(row[0]));

      // Prepare the data for the first chart
      const labels1 = [...new Set(filteredRows1.map(row => row[1]))]; // Periods
      const datasets1 = dataElements1.map((dx, index) => {
        const data = labels1.map(label => {
          const row = filteredRows1.find(r => r[0] === dx && r[1] === label);
          return row ? parseFloat(row[3]) : 0;
        });
        return {
          label: data1.metaData.items[dx].name,
          data,
          backgroundColor: themeColors[index % themeColors.length],
          borderColor: themeColors[index % themeColors.length],
        };
      });

      // Prepare the data for the second chart
      const labels2 = dataElements2.map(dx => data1.metaData.items[dx].name); // Data elements as labels
      const datasets2 = [{
        label: '2023',
        data: filteredRows2.map(row => parseFloat(row[3])),
        backgroundColor: themeColors[2 % themeColors.length],
        borderColor: themeColors[2 % themeColors.length],
      }];

      // Prepare the data for the third chart
      const labels3 = dataElements3.map(dx => data1.metaData.items[dx].name); // Data elements as labels
      const datasets3 = [{
        label: '2023',
        data: filteredRows3.map(row => parseFloat(row[3])),
        backgroundColor: themeColors[3 % themeColors.length],
        borderColor: themeColors[3 % themeColors.length],
      }];

      // Prepare the data for the fourth chart
      const labels4 = [...new Set(filteredRows4.map(row => row[1]))]; // Periods
      const datasets4 = dataElements4.map((dx, index) => {
        const data = labels1.map(label => {
          const row = filteredRows4.find(r => r[0] === dx && r[1] === label);
          return row ? parseFloat(row[3]) : 0;
        });
        return {
          label: data1.metaData.items[dx].name,
          data,
          backgroundColor: themeColors[index % themeColors.length],
          borderColor: themeColors[index % themeColors.length],
        };
      });

      setChartData1({
        labels: labels1,
        datasets: datasets1,
      });

      setChartData2({
        labels: labels2,
        datasets: datasets2,
      });

      setChartData3({
        labels: labels3,
        datasets: datasets3,
      });
      
      setChartData4({
        labels: labels4,
        datasets: datasets4,
      });

      // Prepare table data for provinces as rows and data elements as columns for selected year
      const prepareTableDataByYear = () => {
        const provinces = data1.metaData.dimensions.ou.filter(ou => ou !== ''); // Exclude national level
        const tableRows = provinces.map(province => {
          const row = { province: data1.metaData.items[province]?.name || 'Unknown' };
          dataElements.forEach((element, index) => {
            const dataRow = data1.rows.find(r => r[0] === element && r[1] === selectedYear && r[2] === province);
            row[element] = dataRow ? { value: parseFloat(dataRow[3]), color: themeColors[index % themeColors.length] } : { value: '-', color: 'transparent' };
          });
          return row;
        });
        return tableRows;
      };

      setTableDataByYear(prepareTableDataByYear());
    }
  }, [data, loading, selectedYear]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cases',
        },
      },
    },
  };

  const handleDownload = (chartRef) => {
    if (chartRef && chartRef.current) {
      const link = document.createElement('a');
      link.href = chartRef.current.toBase64Image();
      link.download = 'chart.png';
      link.click();
    } else {
      console.error("Chart ref is not defined or current is null");
    }
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
  };

  // Extracting 2024 data elements for the top data box
  const getDataElementValue = (data1, elementId) => {
    const row = data1.rows.find(row => row[0] === elementId && row[1] === '2023');
    return row ? parseFloat(row[3]).toLocaleString() : 'N/A';
  };

  const dataBoxes = [
    {
      title: 'HIV Tested',
      elements: [
        { id: 'GwfwBw4iwnY', source: 'data1', label: 'Tested' },
      ],
      background: 'linear-gradient(to bottom right, #82d9d1, #34d6bd)',
      icon: <FontAwesomeIcon icon={faRibbon} className="icon-background" />,
    },
    {
      title: 'HIV Positive',
      elements: [
        { id: 't0wTKNT7BoP', source: 'data1', label: 'Positive' }
      ],
      background: 'linear-gradient(to bottom right, #ffbc96, #fc7498)',
      icon: <FontAwesomeIcon icon={faRibbon} className="icon-background" />,
    },
    {
      title: 'Death',
      elements: [
        { id: 'BEPCKBJ0t6t', source: 'data1', label: 'Death' }
      ],
      background: 'linear-gradient(to bottom right, #8cc8f8, #3798e6)',
      icon: <FontAwesomeIcon icon={faRibbon} className="icon-background" />,
    },
    {
      title: 'HIV positive pregnant women',
      elements: [
        { id: 'SghlO61QCNL', source: 'data1', label: 'pregnant women' }
      ],
      background: 'linear-gradient(to bottom right,  #f19ff6, #c17efe)',
      icon: <FontAwesomeIcon icon={faRibbon} className="icon-background" />,
    },
    {
      title: 'positive	HIV positive TB',
      elements: [
        { id: 'tikqFtKTzlR', source: 'data1', label: 'positive	HIV positive TB' }
      ],
      background: 'linear-gradient(to bottom right, #ffd700, #ffa500)',
      icon: <FontAwesomeIcon icon={faRibbon} className="icon-background" />,
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <p className="loading-text">Please wait...</p>
        </div>
      ) : (
        <>
          <div className="data-boxes-container1">
            {dataBoxes.map((box, index) => (
              <div key={index} className="data-box" style={{ background: box.background, color: 'white', position: 'relative' }}>
                <div className="data-element">
                  <h3>{box.title}</h3>
                  {box.elements.map((element, idx) => (
                    <p key={idx}>
                      {element.label} | {data && data[element.source] ? getDataElementValue(data[element.source], element.id) : 'N/A'}
                    </p>
                  ))}
                </div>
                <div className="icon-container">{box.icon}</div>
              </div>
            ))}
          </div>
          <div className="charts-container">
            {chartData1 && (
              <div className="chart-item">
                <Bar data={chartData1} options={options} ref={chartRefs.current[0]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[0])} />
              </div>
            )}
            {chartData2 && (
              <div className="chart-item">
                <Bar data={chartData2} options={options} ref={chartRefs.current[1]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[1])} />
              </div>
            )}
            {chartData3 && (
              <div className="chart-item">
                <Bar data={chartData3} options={options} ref={chartRefs.current[2]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[2])} />
              </div>
            )}
            {chartData4 && (
              <div className="chart-item">
                <Bar data={chartData4} options={options} ref={chartRefs.current[3]} />
                <FontAwesomeIcon icon={faDownload} className="download-icon" onClick={() => handleDownload(chartRefs.current[3])} />
              </div>
            )}
          </div>
          <div className="table-container">
            <div className="year-selector">
              <label>Year  </label>
              {data?.data1?.metaData?.dimensions?.pe?.map(year => (
                <button
                  key={year}
                  className={`year-button ${year === selectedYear ? 'active' : ''}`}
                  onClick={() => handleYearButtonClick(year)}
                >
                  {year}
                </button>
              ))}
            </div>
            <h3></h3>
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Province</th>
                  {dataElements.map(elementId => (
                    <th key={elementId}>{data?.data1?.metaData?.items[elementId]?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableDataByYear.map((row, index) => (
                  <tr key={index}>
                    <td>{row.province}</td>
                    {dataElements.map(elementId => (
                      <td key={elementId} style={{ textAlign: 'right'}}>
                        {row[elementId].value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default HIV;
